<template>
  <div class="p-grid">
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_alarm_id') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-key"></i>
                    </span>
        <InputText v-model="getApp.alarmPointId"
                   :disabled="alarmIdDisabled"
                   :placeholder="_t('label_alarm_id')"
                   class="p-m-0"
                   style="width:100%;"
                   type="text"
        />
      </div>
      <p v-else><strong>{{ getApp.alarmPointId }}</strong></p>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_Alarm_name') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
        <InputText v-model="getApp.name"
                   :placeholder="_t('label_Alarm_name')"
                   class="p-m-0"
                   style="width:100%;"
        />
      </div>
      <p v-else><strong>{{ getApp.name }}</strong></p>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <Dropdown
          v-if="isEditable"
          v-model="getApp.confirmPriority"
          :options="[1,2,3,4,5,6,7,8,9,10]"
          class="p-m-0"
          style="width: 100%;"/>
      <p v-else><strong>{{ getApp.confirmPriority }}</strong></p>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>

      <div v-if="isEditable" class="p-inputgroup p-mt-5">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <Dropdown
            v-model="selectedTextVar"
            :disabled="!isEditable"
            :options="textVars"
            :placeholder="_t('select_predefined_variable')"
            class="p-m-0"
            style="width: 100%;"
            @change="getApp.text += selectedTextVar"
        />
      </div>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Textarea
          v-if="isEditable"
          v-model="getApp.text"
          :placeholder="_t('label_alarm_text')"
          class="p-m-0"
          rows="5"
          style="width:100%"
      />
      <p v-else><strong>{{ getApp.text }}</strong></p>
      <br/>
      <small v-if="alarmPointError.includes('getApp.text')" class="p-error">Text is required.</small>
    </div>
    <div class="p-col-3 stripedBg" style="vertical-align: middle;">
      <p>{{ _t('label_system_id') }}<sup>*</sup></p>
    </div>
    <div class="p-col-9 stripedBg" style="vertical-align: middle;">
      <div class="p-inputgroup">
        <p>
          <strong>{{ getApp.systemId || '' }}</strong>
        </p>
      </div>
    </div>
    <div class="p-col-12">
      <h3 class="subhead p-mt-4">&nbsp;</h3>
    </div>
    <div class="p-col-3" style="vertical-align: middle;">
      <p>{{ _t('label_description') }}</p>
    </div>
    <div class="p-col-9" style="vertical-align: middle;">
      <Textarea
          v-if="isEditable"
          v-model="getApp.description"
          :placeholder="_t('label_description')"
          class="p-m-0"
          rows="5"
          style="width:100%"/>
      <p v-else><strong>{{ getApp.description ?? '...' }}</strong></p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'follow-up-basics-tab',
  components: {},
  props: {
    isEditable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selectedTextVar: null,
    textVars: [
      '<original>', '<K>', '<G>', '<family>', '<callerid>'
    ],
    alarmPointError: [],
    alarmIdDisabled: true
  }),
  mounted() {
    if (!this.getApp.alarmPointId) {
      this.alarmIdDisabled = false
    }
  },
  methods: {
    ...mapActions({}),
  },
  computed: {
    ...mapGetters({
      'getApp': 'apps/getApp',
    }),
  }
}
</script>
